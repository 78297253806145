const APP = {
  PHOTOS_PER_PAGE: 12,
  MAX_PHOTOS_COUNT: Number.POSITIVE_INFINITY,
  BREAKPOINTS: {
    mobile: 520,
    tablet: 768,
    desktop: 992,
  },
  COLUMN_COUNT: {
    mobile: 2,
    tablet: 3,
    desktop: 4,
  },
  CART_COLUMN_COUNT: {
    mobile: 1,
    tablet: 2,
    desktop: 3,
  },
  SCROLL_Y_BOUND: 800,
  LOCAL_STORAGE_NAME: 'photo_store_cart',
  TAGS: [
    'Technology',
    'Nature',
    'People',
    'Ocean',
    'Autumn',
    'Planes',
    'Chill',
    'Sports',
    'Animals',
    'Architecture',
    'Indoor Plants',
    'Health',
    'Memes',
    'Winter',
    'Coffee break',
    'Books',
    'Rural',
    'Neon',
    'Food',
    'Children',
    'Music',
    'Design',
    'Internet',
    'Memories',
    'Art',
    'Romantic',
    'Finance',
    'Woodwork',
    'Spirituality',
    'Mountains',
    'Cozy home',
    'Science',
    'Friends',
    'Retro',
  ],
};

export default APP;
